import Vue from 'vue';
import { Link } from '@inertiajs/vue2';
import Sortable from 'sortablejs';

Vue.component('RouterLink', {
    functional: true,

    render(h, context) {
        const data = { ...context.data };
        delete data.nativeOn;
        const props = data.props || {};
        props.href = props.to; /// v-btn passes `to` prop but inertia-link requires `href`, so we just copy it
        return h('inertia-link', data, context.children);
    },
});

Vue.component('InertiaLink', Link);

Vue.mixin({
    computed: {
        $routeParams() {
            return Object.fromEntries(new URLSearchParams(window.location.search));
        },
    },
});

export const VueSortableDataTable = {
    // Called only once, when the directive is first bound to the element
    bind(el, binding, vnode) {
        // Optional: store reference to the table <tbody>, so we don't keep
        // looking it up every time.
        el.__sortableTarget = el.querySelector('tbody');

        // Attempt to enable if bound value is truthy
        if (binding.value) {
            enableSortable(el, vnode);
        }
    },

    // Called whenever the bound value changes
    update(el, binding, vnode) {
        const wasEnabled = !!binding.oldValue;
        const isEnabled = !!binding.value;

        // If it changed from false -> true, enable
        if (!wasEnabled && isEnabled) {
            enableSortable(el, vnode);
        }
        // If it changed from true -> false, disable
        else if (wasEnabled && !isEnabled) {
            disableSortable(el);
        }
        // Otherwise do nothing
    },

    // Called only once, when the directive is unbound from the element
    unbind(el) {
        disableSortable(el);
        delete el.__sortableTarget;
    },
};

function enableSortable(el, vnode) {
    if (!el.__sortableInstance && el.__sortableTarget) {
        el.__sortableInstance = Sortable.create(el.__sortableTarget, {
            animation: 150,
            onUpdate(event) {
                // Emit a `sorted` event on the component
                // so you can listen with @sorted="onDragEnd"
                if (vnode.componentInstance) {
                    vnode.componentInstance.$emit('sorted', event);
                }
                // (For Vue 2, vnode.componentInstance is generally correct.
                //  If using Vue 3, you'd do binding.instance.$emit.)
            },
        });
    }
}

/** Helper that destroys the Sortable instance if present. */
function disableSortable(el) {
    if (el.__sortableInstance) {
        el.__sortableInstance.destroy();
        el.__sortableInstance = null;
    }
}

import Vuex from 'vuex';
import VuexORM from '@vuex-orm/core';
import VuexORMAxios from '@vuex-orm/plugin-axios';
import axios from 'axios';
import database from '@/Bootstrap/models.js';
import VideoPlayer from '@/Store/VideoPlayer.js';

const store = new Vuex.Store({
    plugins: [VuexORM.install(database)],

    modules: {
        VideoPlayer,
    },

    state: {
        debugMode: false,
        forceHardNavigation: false,
        globalVideoPlayer: {},
        verifiableMeetIds: [],
    },

    mutations: {
        SET_DEBUG_MODE(state, debug) {
            state.debugMode = debug;
        },

        SET_FORCE_HARD_NAVIGATION(state, force) {
            state.forceHardNavigation = force;
        },

        SET_GLOBAL_VIDEO_PLAYER(state, player) {
            state.globalVideoPlayer = player;
        },

        SET_VERIFIABLE_MEET_IDS(state, meetIds) {
            state.verifiableMeetIds = meetIds === '*' ? '*' : meetIds.map(meetId => Number.parseInt(meetId, 10));
        },

        ADD_VERIFIABLE_MEET_ID(state, meetId) {
            if (!state.verifiableMeetIds.includes(meetId)) {
                state.verifiableMeetIds.push(meetId);
            }
        },
    },
});

VuexORM.use(VuexORMAxios, {
    axios,
    baseURL: '/',
    dataKey: 'data',
});

export default store;
